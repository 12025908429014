import React from "react";

export default function FormErrorText({
  children,
}: {
  children?: React.ReactNode;
}) {
  return (
    <label className="text-nxu-charging-red text-[12px]">{children}</label>
  );
}
