import Button from "@root/components/Button";
import { FC } from "react";

const Landing: FC = () => {
  return (
      <div className="w-full h-full pt-16 pb-8 flex flex-col items-center bg-white overflow-scroll sm:pt-8">
        <div className="flex flex-col w-[66.64%] gap-6 items-center justify-center sm:w-1/2 md:w-1/4">
          <img src="/nxuOneLogo.png"/>
          <p className="font-bold">
            Upgrade to our new app and login using your current credentials!
          </p>
          <div className="flex flex-col items-center gap-2">
            <a href="https://apps.apple.com/us/app/nxuone/id6476523186">
              <img src="/appleAppStoreBtn.png"/>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.nxu.chargingreactnativeapp">
              <img src="/googlePlayStoreBtn.png"/>
            </a>
          </div>
          <p>OR</p>
          <a href="/auth-sign-in">
            <Button
              className={`rounded-full text-sm bg-nxu-charging-gold normal-case !font-extrabold !px-6 !py-4 !m-0`}
            >
              Continue to Mobile Site
            </Button>
          </a>
        </div>
      </div>
  );
};

export default Landing;
