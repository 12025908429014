import { PropsWithChildren, useEffect } from "react";

import SiteHeader from "./SiteHeader";
import ToastProvider from "@root/routes/ToastProvider";
import { useLocation } from "react-router";

function SiteContainer({ children }: PropsWithChildren) {
  const { pathname } = useLocation();

  return (
    <>
      {pathname !== '/' && <SiteHeader />}
      <ToastProvider>
        <div className={`
          ${pathname !== '/' ? 'h-[calc(100dvh_-_75px)]' : 'h-[calc(100dvh)]'} bg-nxu-charging-grey
        `}>
          {children}
        </div>
      </ToastProvider>
    </>
  );
}

export default SiteContainer;
