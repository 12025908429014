import { ToastContext, ToastVariant } from "@root/routes/ToastProvider";

type Props = {
  message: string;
  variant: ToastVariant;
};

export default function ToastBox({ message, variant }: Props) {
  const colors = {
    success: "text-nxu-charging-green",
    error: "text-nxu-charging-red",
    info: "",
  };

  return (
    <ToastContext.Consumer>
      {(_value) => (
        <div className="flex-[100%] m-3">
          {isDisplayable(message) && (
            <p
              className={`text-center text-[12px] ${
                colors[variant as ToastVariant]
              }`}
            >
              {message}
            </p>
          )}
        </div>
      )}
    </ToastContext.Consumer>
  );
}

function isDisplayable(message?: string) {
  return message && message !== "";
}
